// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,600);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*IMPORTAR OUTRO CSS AQUI DENTYRO*/\n\n* {\n  box-sizing: border-box;\n  border: none;\n  color: white;\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n  outline: 0;\n}\n\n::-moz-focus-inner {\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://src/ui/style.css"],"names":[],"mappings":"AAEA,kCAAkC;;AAIlC;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,kCAAkC;EAClC,SAAS;EACT,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,600');\n\n/*IMPORTAR OUTRO CSS AQUI DENTYRO*/\n\n@import 'fonts.css';\n\n* {\n  box-sizing: border-box;\n  border: none;\n  color: white;\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n  outline: 0;\n}\n\n::-moz-focus-inner {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
