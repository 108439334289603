import React, { useCallback } from 'react';
import { List } from 'immutable';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { Select, ItemModel } from '../../ui';

export const FONT_FAMILY_OPTIONS = List([
  ItemModel({ label: 'sans-serif', value: 'sans-serif' }),
  ItemModel({ label: 'graphik-black-trial', value: 'graphik-black-trial' }),
  ItemModel({
    label: 'graphik-blackitalic-trial',
    value: 'graphik-blackitalic-trial',
  }),
  ItemModel({ label: 'graphik-bold-trial', value: 'graphik-bold-trial' }),
  ItemModel({
    label: 'graphik-bolditalic-trial',
    value: 'graphik-bolditalic-trial',
  }),
  ItemModel({
    label: 'graphik-extralight-trial',
    value: 'graphik-extralight-trial',
  }),
  ItemModel({
    label: 'graphik-extralightitalic-trial',
    value: 'graphik-extralightitalic-trial',
  }),
  ItemModel({ label: 'graphik-light-trial', value: 'graphik-light-trial' }),
  ItemModel({
    label: 'graphik-lightitalic-trial',
    value: 'graphik-lightitalic-trial',
  }),
  ItemModel({
    label: 'graphik-medium-trial',
    value: 'graphik-medium-trial',
  }),
  ItemModel({
    label: 'graphik-mediumitalic-trial',
    value: 'graphik-mediumitalic-trial',
  }),
  ItemModel({
    label: 'graphik-regular-trial',
    value: 'graphik-regular-trial',
  }),
  ItemModel({
    label: 'graphik-regularitalic-trial',
    value: 'graphik-regularitalic-trial',
  }),
  ItemModel({
    label: 'graphik-semibold-trial',
    value: 'graphik-semibold-trial',
  }),
  ItemModel({
    label: 'graphik-semibolditalic-trial',
    value: 'graphik-semibolditalic-trial',
  }),
  ItemModel({ label: 'graphik-super-trial', value: 'graphik-super-trial' }),
  ItemModel({
    label: 'graphik-superitalic-trial',
    value: 'graphik-superitalic-trial',
  }),
  ItemModel({ label: 'graphik-thin-trial', value: 'graphik-thin-trial' }),
  ItemModel({
    label: 'graphik-thinitalic-trial',
    value: 'graphik-thinitalic-trial',
  }),
  ItemModel({ label: 'industrytest-black', value: 'industrytest-black' }),
  ItemModel({
    label: 'industrytest-blackitalic',
    value: 'industrytest-blackitalic',
  }),
  ItemModel({ label: 'industrytest-bold', value: 'industrytest-bold' }),
  ItemModel({
    label: 'industrytest-bolditalic',
    value: 'industrytest-bolditalic',
  }),
  ItemModel({ label: 'industrytest-book', value: 'industrytest-book' }),
  ItemModel({
    label: 'industrytest-bookitalic',
    value: 'industrytest-bookitalic',
  }),
  ItemModel({ label: 'industrytest-demi', value: 'industrytest-demi' }),
  ItemModel({
    label: 'industrytest-demiitalic',
    value: 'industrytest-demiitalic',
  }),
  ItemModel({ label: 'industrytest-light', value: 'industrytest-light' }),
  ItemModel({
    label: 'industrytest-lightitalic',
    value: 'industrytest-lightitalic',
  }),
  ItemModel({ label: 'industrytest-medium', value: 'industrytest-medium' }),
  ItemModel({
    label: 'industrytest-mediumitalic',
    value: 'industrytest-mediumitalic',
  }),
  ItemModel({ label: 'industrytest-thin', value: 'industrytest-thin' }),
  ItemModel({
    label: 'industrytest-thinitalic',
    value: 'industrytest-thinitalic',
  }),
  ItemModel({ label: 'industrytest-ultra', value: 'industrytest-ultra' }),
  ItemModel({
    label: 'industrytest-ultraitalic',
    value: 'industrytest-ultraitalic',
  }),
  ItemModel({ label: 'kaleko105bold', value: 'kaleko105bold' }),
  ItemModel({
    label: 'kaleko105boldoblique',
    value: 'kaleko105boldoblique',
  }),
  ItemModel({ label: 'kaleko105book', value: 'kaleko105book' }),
  ItemModel({
    label: 'kaleko105bookoblique',
    value: 'kaleko105bookoblique',
  }),
  ItemModel({ label: 'kaleko105heavy', value: 'kaleko105heavy' }),
  ItemModel({
    label: 'kaleko105heavyoblique',
    value: 'kaleko105heavyoblique',
  }),
  ItemModel({ label: 'kaleko105light', value: 'kaleko105light' }),
  ItemModel({
    label: 'kaleko105lightoblique',
    value: 'kaleko105lightoblique',
  }),
  ItemModel({ label: 'kaleko105thin', value: 'kaleko105thin' }),
  ItemModel({
    label: 'kaleko105thinoblique',
    value: 'kaleko105thinoblique',
  }),
  ItemModel({ label: 'neue-plak-black', value: 'neue-plak-black' }),
  ItemModel({ label: 'neue-plak-bold', value: 'neue-plak-bold' }),
  ItemModel({
    label: 'neue-plak-condensed-black',
    value: 'neue-plak-condensed-black',
  }),
  ItemModel({
    label: 'neue-plak-condensed-bold',
    value: 'neue-plak-condensed-bold',
  }),
  ItemModel({
    label: 'neue-plak-condensed-extrablack',
    value: 'neue-plak-condensed-extrablack',
  }),
  ItemModel({
    label: 'neue-plak-condensed-light',
    value: 'neue-plak-condensed-light',
  }),
  ItemModel({
    label: 'neue-plak-condensed-regular',
    value: 'neue-plak-condensed-regular',
  }),
  ItemModel({
    label: 'neue-plak-condensed-semibold',
    value: 'neue-plak-condensed-semibold',
  }),
  ItemModel({
    label: 'neue-plak-condensed-thin',
    value: 'neue-plak-condensed-thin',
  }),
  ItemModel({
    label: 'neue-plak-condensed-ultralight',
    value: 'neue-plak-condensed-ultralight',
  }),
  ItemModel({
    label: 'neue-plak-extended-black',
    value: 'neue-plak-extended-black',
  }),
  ItemModel({
    label: 'neue-plak-extended-bold',
    value: 'neue-plak-extended-bold',
  }),
  ItemModel({
    label: 'neue-plak-extended-extrablack',
    value: 'neue-plak-extended-extrablack',
  }),
  ItemModel({
    label: 'neue-plak-extended-light',
    value: 'neue-plak-extended-light',
  }),
  ItemModel({
    label: 'neue-plak-extended-regular',
    value: 'neue-plak-extended-regular',
  }),
  ItemModel({
    label: 'neue-plak-extended-semibold',
    value: 'neue-plak-extended-semibold',
  }),
  ItemModel({
    label: 'neue-plak-extended-thin',
    value: 'neue-plak-extended-thin',
  }),
  ItemModel({
    label: 'neue-plak-extended-ultralight',
    value: 'neue-plak-extended-ultralight',
  }),
  ItemModel({
    label: 'neue-plak-extrablack',
    value: 'neue-plak-extrablack',
  }),
  ItemModel({ label: 'neue-plak-light', value: 'neue-plak-light' }),
  ItemModel({ label: 'neue-plak-regular', value: 'neue-plak-regular' }),
  ItemModel({ label: 'neue-plak-semibold', value: 'neue-plak-semibold' }),
  ItemModel({
    label: 'neue-plak-text-black-italic',
    value: 'neue-plak-text-black-italic',
  }),
  ItemModel({
    label: 'neue-plak-text-black',
    value: 'neue-plak-text-black',
  }),
  ItemModel({
    label: 'neue-plak-text-bold-italic',
    value: 'neue-plak-text-bold-italic',
  }),
  ItemModel({ label: 'neue-plak-text-bold', value: 'neue-plak-text-bold' }),
  ItemModel({
    label: 'neue-plak-text-italic',
    value: 'neue-plak-text-italic',
  }),
  ItemModel({
    label: 'neue-plak-text-light-italic',
    value: 'neue-plak-text-light-italic',
  }),
  ItemModel({
    label: 'neue-plak-text-light',
    value: 'neue-plak-text-light',
  }),
  ItemModel({
    label: 'neue-plak-text-semibold-italic',
    value: 'neue-plak-text-semibold-italic',
  }),
  ItemModel({
    label: 'neue-plak-text-semibold',
    value: 'neue-plak-text-semibold',
  }),
  ItemModel({
    label: 'neue-plak-text-thin-italic',
    value: 'neue-plak-text-thin-italic',
  }),
  ItemModel({ label: 'neue-plak-text-thin', value: 'neue-plak-text-thin' }),
  ItemModel({ label: 'neue-plak-thin', value: 'neue-plak-thin' }),
  ItemModel({
    label: 'neue-plak-ultralight',
    value: 'neue-plak-ultralight',
  }),
  ItemModel({ label: 'teko-bold', value: 'teko-bold' }),
  ItemModel({ label: 'teko-light', value: 'teko-light' }),
  ItemModel({ label: 'teko-medium', value: 'teko-medium' }),
  ItemModel({ label: 'teko-regular', value: 'teko-regular' }),
  ItemModel({ label: 'teko-semibold', value: 'teko-semibold' }),
  ItemModel({
    label: 'teko-variablefont_wght',
    value: 'teko-variablefont_wght',
  }),
  ItemModel({ label: 'xtradex', value: 'xtradex' }),
  ItemModel({ label: 'alesand-bold', value: 'alesand-bold' }),
  ItemModel({ label: 'alesand-bold', value: 'alesand-bold' }),
  ItemModel({ label: 'alesand-extra-bold', value: 'alesand-extra-bold' }),
  ItemModel({ label: 'alesand-extra-bold', value: 'alesand-extra-bold' }),
  ItemModel({ label: 'alesand-extra-extrude', value: 'alesand-extra-extrude' }),
  ItemModel({ label: 'alesand-extra-extrude', value: 'alesand-extra-extrude' }),
  ItemModel({ label: 'alesand-extra-outline', value: 'alesand-extra-outline' }),
  ItemModel({ label: 'alesand-extra-outline', value: 'alesand-extra-outline' }),
  ItemModel({ label: 'alesand-extra-round', value: 'alesand-extra-round' }),
  ItemModel({ label: 'alesand-extra-round', value: 'alesand-extra-round' }),
  ItemModel({ label: 'alesand-light', value: 'alesand-light' }),
  ItemModel({ label: 'alesand-light', value: 'alesand-light' }),
  ItemModel({ label: 'alesand-regular', value: 'alesand-regular' }),
  ItemModel({ label: 'alesand-regular', value: 'alesand-regular' }),
  ItemModel({ label: 'hunterra', value: 'hunterra' }),
  ItemModel({ label: 'impacted', value: 'impacted' }),
  ItemModel({ label: 'montserrat-black', value: 'montserrat-black' }),
  ItemModel({
    label: 'montserrat-blackitalic',
    value: 'montserrat-blackitalic',
  }),
  ItemModel({ label: 'montserrat-bold', value: 'montserrat-bold' }),
  ItemModel({ label: 'montserrat-bolditalic', value: 'montserrat-bolditalic' }),
  ItemModel({ label: 'montserrat-extrabold', value: 'montserrat-extrabold' }),
  ItemModel({
    label: 'montserrat-extrabolditalic',
    value: 'montserrat-extrabolditalic',
  }),
  ItemModel({ label: 'montserrat-extralight', value: 'montserrat-extralight' }),
  ItemModel({
    label: 'montserrat-extralightitalic',
    value: 'montserrat-extralightitalic',
  }),
  ItemModel({
    label: 'montserrat-italic-variablefont_wght',
    value: 'montserrat-italic-variablefont_wght',
  }),
  ItemModel({ label: 'montserrat-italic', value: 'montserrat-italic' }),
  ItemModel({ label: 'montserrat-light', value: 'montserrat-light' }),
  ItemModel({
    label: 'montserrat-lightitalic',
    value: 'montserrat-lightitalic',
  }),
  ItemModel({ label: 'montserrat-medium', value: 'montserrat-medium' }),
  ItemModel({
    label: 'montserrat-mediumitalic',
    value: 'montserrat-mediumitalic',
  }),
  ItemModel({ label: 'montserrat-regular', value: 'montserrat-regular' }),
  ItemModel({ label: 'montserrat-semibold', value: 'montserrat-semibold' }),
  ItemModel({
    label: 'montserrat-semibolditalic',
    value: 'montserrat-semibolditalic',
  }),
  ItemModel({ label: 'montserrat-thin', value: 'montserrat-thin' }),
  ItemModel({ label: 'montserrat-thinitalic', value: 'montserrat-thinitalic' }),
  ItemModel({
    label: 'montserrat-variablefont_wght',
    value: 'montserrat-variablefont_wght',
  }),
  ItemModel({
    label: 'ppmonumentextended-black-bf645465a1e687b',
    value: 'ppmonumentextended-black-bf645465a1e687b',
  }),
  ItemModel({
    label: 'ppmonumentextended-blackitalic-bf645465a213d75',
    value: 'ppmonumentextended-blackitalic-bf645465a213d75',
  }),
  ItemModel({
    label: 'ppmonumentextended-light-bf645465a264007',
    value: 'ppmonumentextended-light-bf645465a264007',
  }),
  ItemModel({
    label: 'ppmonumentextended-lightitalic-bf645465a25b74d',
    value: 'ppmonumentextended-lightitalic-bf645465a25b74d',
  }),
  ItemModel({
    label: 'ppmonumentextended-regular-bf645465a253250',
    value: 'ppmonumentextended-regular-bf645465a253250',
  }),
  ItemModel({
    label: 'ppmonumentextended-regularitalic-bf645465a2449f3',
    value: 'ppmonumentextended-regularitalic-bf645465a2449f3',
  }),
  ItemModel({ label: 'suzuki-pro-bold.woff', value: 'suzuki-pro-bold.woff' }),
  ItemModel({ label: 'suzukiproregular', value: 'suzukiproregular' }),
  ItemModel({ label: 'impact', value: 'impact' }),
  ItemModel({ label: 'unicode.impact', value: 'unicode.impact' }),
]);

export const searchFontFamily = fontFamily =>
  FONT_FAMILY_OPTIONS.find(
    fontOption => fontOption.get('value') === fontFamily,
  );

const useStyles = createUseStyles({
  fontFamily: {
    boxSizing: 'border-box',
    display: 'flex',
    width: 240,
  },
});
const FontFamily = ({ fontFamily, disabled, fontFamilyChange }) => {
  const onChange = useCallback(
    newValue => {
      fontFamilyChange(newValue.value);
    },
    [fontFamily],
  );
  const classes = useStyles();
  return (
    <div className={classes.fontFamily}>
      <Select
        placeholder="Font Family"
        items={FONT_FAMILY_OPTIONS}
        value={searchFontFamily(fontFamily)}
        onChange={onChange}
        rounded={false}
        disabled={disabled}
      />
    </div>
  );
};

FontFamily.propTypes = {
  fontFamily: PropTypes.string,
  disabled: PropTypes.bool,
  fontFamilyChange: PropTypes.func,
};

FontFamily.defaultProps = {
  fontFamily: 'sans-serif',
  disabled: true,
  fontFamilyChange: () => {},
};

export default FontFamily;
